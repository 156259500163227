.modal {
    width: 600px;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    min-height: 100px;
    background-color: var(--main-bg-color);
    padding: 15px;
}

.closeIcon {
    font-size: 30px;
    color: var(--incorrect);
    cursor: pointer;
}

.closeContainer {
    display: flex;
    justify-content: flex-end;
}

.title {
    font-size: 24px;
    color: #fff;
    font-weight: 600;
    text-align: center;
}