body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
:root {
  --correct: #26a100;
  --yellow: #bdaa00;
  --incorrect: rgb(51, 51, 51);
  --default: #7a7a7a;
  --text-color: #fff;
  --main-bg-color: rgb(14, 14, 14);
  --secondary-bg-color: rgb(22, 22, 22);
}

body {
  height: 100%;
  width: 100%;
  max-width: 100vw;
  background-color: var(--main-bg-color) !important;
}

.app-container {
  flex: 1;
  width: 100%;
  max-width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-x: hidden !important;
}

.content-container {
  padding: 10px 0;
  box-sizing: border-box;
  max-width: 100%;
  width: 500px;
  flex: 1;
  max-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.keyboard-container {
  padding: 10px 0;
  max-width: 100% !important;
  min-width: 0;
  width: 500px;
  box-sizing: border-box;
  height: fit;
}

@media only screen and (max-width: 767px) {
  .content-container {
    padding: 0;
  }
  .keyboard-container {
    padding: 0;
  }
  body,
  .app-container {
    height: stretch;
  }
}

.title {
  font-size: 24px;
  text-align: center;
  color: var(--text-color);
  font-weight: 700;
}

.subTitle {
  font-size: 20px;
  text-align: center;
  color: var(--text-color);
  font-weight: 600;
}
